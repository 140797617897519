/* Footer Styles - Footer.css */
.footer {
    width: 100%;
    padding: 30px;
    background-color: #2c3e50;
    color: #ecf0f1;
    text-align: center;
    font-size: 0.9rem;
    border-top: 4px solid #e74c3c;
  }
  
  .footer a {
    color: #ecf0f1;
    text-decoration: none;
  }
  
  .footer a:hover {
    color: #bdc3c7;
  }
  