.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    background-color: #34495e;

    color: #fff;
    padding-top: 80px; /* Adjusted for better spacing */
    z-index: 1000;
    transform: translateX(-250px);
    transition: transform 0.3s ease-in-out;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: #ecf0f1;
    background: none;
    border: none;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .close-btn:hover {
    color: #e74c3c;
  }
  

.sidebar.show {
    transform: translateX(0);
}

.sidebar a {
    display: block;
    padding: 15px 20px; /* Comfortable padding */
    color: #fff;
    text-decoration: none;
    transition: background-color 0.2s, padding-left 0.2s;
}

.sidebar a:hover {
    padding-left: 30px; /* Slight indent on hover */
    color: #e74c3c; /* Consistent with the theme */
}

.sidebar-divider {
    border-top: 1px solid #575757;
    margin: 20px 0;
}

.featured-project {
    padding: 15px 20px;
    background-color: #1e1e1e;
    border-radius: 5px;
    color: #fff;
}

.featured-project h3 {
    margin-bottom: 10px;
    color: #e74c3c; /* Highlight color for the project title */
}

.featured-project a {
    color: #3498db;
    text-decoration: none;
    transition: color 0.2s;
}

.featured-project a:hover {
    color: #2980b9; /* Slightly darker on hover */
}



.business-hours {
    padding: 15px 20px;
    color: #fff;
}

.business-hours h3 {
    margin-bottom: 10px;
    color: #e74c3c;
}

.current-project {
    padding: 15px 20px; /* Same padding as the sidebar links */
    background-color: #34495e;

    border-radius: 5px;
    color: #fff;
}

.current-project h3 {
    margin-bottom: 10px;
    color: #e74c3c; /* Highlight color for the project title */
}


.table-of-contents {
    padding: 15px 20px;
}

.table-of-contents h3 {
    color: #e74c3c;
    margin-bottom: 10px;
}

.table-of-contents ul {
    list-style: none;
    padding: 0;
}

.table-of-contents li {
    margin-bottom: 10px;
}

.table-of-contents a {
    color: #ffffff;
    text-decoration: none;
    transition: background-color 0.2s, padding-left 0.2s; /* Consistent smooth transition */
    display: block;
    padding: 12px 18px; /* Same padding as other sidebar links */
}

.table-of-contents a:hover {
   
    padding-left: 25px; /* Subtle indent on hover */
}


/* Mobile adjustments */
@media (max-width: 768px) {
    .sidebar {
        position: fixed;
        top: 0;
        left: 0;
        width: 95%; /* Sidebar now takes up 95% of the screen width */
        height: 100%;
        background-color: #34495e;

        transform: translateX(-100%); /* Hide the sidebar off-screen */
        transition: transform 0.3s ease-in-out;
        z-index: 1000; /* Ensure it stays above other content */
    }

    .sidebar.show {
        transform: translateX(0); /* Slide the sidebar into view */
    }

    .sidebar a {
        padding: 15px 20px; /* Comfortable padding */
        font-size: 16px; /* Slightly larger font for better readability on small screens */
    }

    .sidebar-divider {
        margin: 15px 0; /* Reduce spacing between sections on mobile */
    }

    .current-project,
    .featured-project,
    .business-hours,
    .table-of-contents {
        padding: 15px 20px;
        font-size: 14px; /* Slightly smaller font to fit mobile view */
    }

    .table-of-contents a {
        padding-left: 20px; /* Slightly less indent on hover for mobile */
        font-size: 14px;
    }

    .business-hours h3,
    .table-of-contents h3,
    .current-project h3,
    .featured-project h3 {
        font-size: 16px; /* Adjusted heading size for mobile */
    }
}



/* Overlay styling */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    z-index: 999; /* Just below the sidebar */
    display: none; /* Hidden by default */
    transition: opacity 0.3s ease; /* Smooth transition for showing/hiding */
}

.overlay.show {
    display: block;
    opacity: 1; /* Fully visible when the sidebar is open */
}
