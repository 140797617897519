.slideshow-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.slides-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out; /* Smooth sliding transition */
}

.slide {
  min-width: 100%;
  height: auto;
}

.slide img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.nav-buttons {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between; /* Positions buttons at the edges */
  transform: translateY(-50%);
  padding: 0 20px; /* Adds space from the edges */
}

.nav-buttons button {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nav-buttons button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.nav-buttons button:focus {
  outline: none;
}
