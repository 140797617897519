/* Global Styles - index.css */
html {
  scroll-behavior: smooth;
}

/* Universal Box Sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Global Font and Background */
body {
  font-family: 'Helvetica Neue', Arial, sans-serif; /* Professional, clean font */
  font-size: 16px;
  color: #2c3e50; /* Dark, modern text color */
  background-color: #ecf0f1; /* Subtle, light background */
  line-height: 1.7;
  -webkit-font-smoothing: antialiased; /* Smoother font rendering */
  -moz-osx-font-smoothing: grayscale;
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Helvetica Neue', Arial, sans-serif; /* Consistent font for headings */
  color: #34495e; /* Slightly darker for better contrast */
  font-weight: 700;
  margin-bottom: 1rem;
  text-transform: uppercase; /* Adds a modern touch */
  letter-spacing: 1px; /* Enhances readability */
}

/* Links */
a {
  color: #e74c3c; /* Link color matching the border color */
  text-decoration: none; /* Remove the default underline */
  position: relative; /* Allows us to position the underline */
  transition: color 0.3s ease, text-shadow 0.3s ease; /* Smooth transition for color and text-shadow */
}

a::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #e74c3c; /* Underline color matching the link */
  visibility: hidden; /* Hide by default */
  transform: scaleX(0); /* Start with no underline */
  transition: transform 0.3s ease-in-out; /* Smooth transition for the underline */
}

a:hover::before {
  visibility: visible; /* Show the underline */
  transform: scaleX(1); /* Animate the underline */
}

/* Buttons */
button {
  background-color: #e74c3c;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

button:focus {
  outline: none;
}


/* index.css */

.app-container {
  opacity: 0;
  transition: opacity 0.5s ease-out; /* Only transition opacity */
}

.app-container.loaded {
  opacity: 1;
}

.main-content {
  transform: translateY(20px);
  transition: transform 0.5s ease-out;
}

.main-content.loaded {
  transform: translateY(0);
}


