.articles {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    background-color: #f9f9f9;
}

.article {
    background: #fff;
    width: 100%;
    max-width: 900px;
    margin: 30px 0;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.article:hover {
    transform: translateY(3px);
}

.article-image {
    width: 100%;
    border-radius: 12px;
    margin-bottom: 20px;
}

.article-title {
    font-size: 2.2rem;
    color: #333;
    margin-bottom: 20px;
}

.article-content {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #555;
    margin-bottom: 20px;
}

.article-link, .article-pdf-link {
    display: inline-block;
    width: 100%; /* Ensure all buttons take up the full width */
    max-width: 400px; /* Set a max width to maintain a consistent button size */
    padding: 15px 20px; /* Same padding for all buttons */
    color: #fff; /* White text color */
    background-color: #1e1e1e; /* Dark background color */
    border-radius: 5px;
    text-decoration: none;
    text-align: center; /* Center the text inside the buttons */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
    margin-bottom: 15px; /* Space between buttons */
}

.article-link:hover, .article-pdf-link:hover {
    color: #e74c3c; /* Consistent with the theme */
    transform: translateY(-3px); /* Slight lift effect on hover */
}

.article-pdf-links {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the buttons */
    margin-top: 15px;
}

.article-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
}

.article-gallery-image {
    width: calc(50% - 10px);
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .article-gallery-image {
        width: 100%;
    }

    .article-link, .article-pdf-link {
        width: 100%; /* Ensure full width on smaller screens */
    }
}
